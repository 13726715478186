<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <!-- headerPicture -->
    <div class="heardePicture">
      <div class="heardePicture_text">
        <h1>充电桩后台管理平台</h1>
        <p class="titleEN">CHARGING PILE BACKGROUND MANAGEMENT PLATFORM</p>
        <p class="titleInstructions">
          以用户为中心，全面构建自主可控的公共交通大数据产业链、价值链和生态链，助力用户深度融合移动互联网，运用大数据分析能力推进公共交通决策科学化、治理精准化、公共服务高效化。
        </p>
      </div>
      <div class="heardePicture_menu">
        <ul>
          <li @click="selectPage_1">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据云平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据云平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_2">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/安全平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/安全平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_3">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_4">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/APP出行平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/APP出行平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_5">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/充电桩-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/充电桩-选中.png" alt=""
            /></span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 智慧安全平台 -->
    <div class="busApp">
      <section id="about-us" class="about-us">
        <!-- 标题 -->
        <h2 class="title1">充电桩后台管理平台</h2>
        <!-- 补充说明区域 -->
        <p class="intro">基于移动互联网的充电解决方案</p>
        <!-- 公司业务容器，栅格布局 -->
        <div class="appCard">
          <div class="appCard_item">
            <img src="../../assets/image/产品服务/充电桩/app.png" alt="" />
            <h3>APP互联</h3>
            <p>用户交互体验好，可在手 机上自助充电及选择充电时间</p>
          </div>
          <div class="appCard_item">
            <img src="../../assets/image/产品服务/充电桩/充电.png" alt="" />
            <h3>多种充电模式</h3>
            <p>支持智能充、立即充、定 时充、预约充等多种充电模式</p>
          </div>
          <div class="appCard_item">
            <img src="../../assets/image/产品服务/充电桩/数据.png" alt="" />
            <h3>大数据分析</h3>
            <p>通过大数据分析技术，为 充电设施的运营运维提供数据预测</p>
          </div>
          <div class="appCard_item">
            <img src="../../assets/image/产品服务/充电桩/计费.png" alt="" />
            <h3>灵活计费策略</h3>
            <p>支持按电站、时间、运营 类型、终端类型等多维度计费策略</p>
          </div>
        </div>
      </section>
    </div>
    <!-- 站/桩告警监控 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="../../assets/image/产品服务/充电桩/站桩-配图.png"
          alt=""
        />
        <div class="realTime_right">
          <div class="realTime_title">
            <h2>站/桩告警监控</h2>
          </div>
          <p class="realTime_content">
            对所有场站充电站及充电桩状态实时监控，合理安排车辆充电计划，统筹规划新能源公交车辆的充电时间，保证充电过程的安全性
          </p>
        </div>
      </div>
    </div>
    <!-- 站/桩维护 -->
    <div class="businessHall">
      <div>
        <div class="businessHall_title">
          <h2>站/桩维护</h2>
        </div>
        <p class="businessHall_content">
          充电站、充电桩的基础数据维护，维护充电类型及充电状态，可灵活配置运营时间，自定义配置停车费用及充电费用
        </p>
      </div>
      <img src="../../assets/image/产品服务/充电桩/站桩维护-配图.png" alt="" />
    </div>
    <!-- 营运数据分析 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="../../assets/image/产品服务/充电桩/营运数据分析-配图.png"
          alt=""
        />
        <div class="realTime_right">
          <div class="realTime_title">
            <h2>营运数据分析</h2>
          </div>
          <p class="realTime_content">
            通过大数据分析技术，分析统计各类营运指标，为充电设施的运营运维提供数据预测、分析服务，提高设备盈利能力、降低运维成本，实现对整个充电系统的监控管理
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chooseItem: ''
    }
  },
  methods: {
    selectPage_1() {
      this.$router.push('/productService')
      window.scrollTo(0, 0)
    },
    selectPage_2() {
      this.$router.push('/securityPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_3() {
      this.$router.push('/monitoringPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_4() {
      this.$router.push('/travelApp')
      window.scrollTo(0, 0)
    },
    selectPage_5() {
      this.$router.push('/chargingPile')
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="less" scoped>
.heardePicture {
  position: relative;
  width: 100%;
  height: 1000px;
  background: url('../../assets/image/产品服务/充电桩/banner.png') 0;
  .heardePicture_text {
    position: absolute;
    top: 250px;
    left: 285px;
    width: 600px;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    .titleEN {
      width: 668px;
      font-size: 19px;
      color: #fff;
    }
    .titleInstructions {
      width: 480px;
      font-size: 24px;
      margin-top: 70px;
      color: #fefefe;
    }
  }

  .heardePicture_menu {
    position: fixed;
    z-index: 999;
    top: 222px;
    right: 100px;
    ul {
      width: 124px;
      li {
        width: 124px;
        text-align: center;
      }
    }
  }
}
.busApp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 掌上公交APP */
.about-us {
  position: relative;
}

.appCard {
  width: 100%;
  height: 530px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .appCard_item {
    width: 260px;
    height: 330px;
    margin: 0 25px;
    background-size: cover;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    h3 {
      margin: 40px 0 16px 0;
      font-size: 24px;
      color: #111;
    }
    p {
      width: 200px;
      font-size: 18px;
      color: #999;
      text-align: center;
    }
  }
}

/* 所有section都按grid布局 */
section {
  display: grid;
  /* 单元格都居中对齐 */
  justify-items: center;
  width: 100%;
}

/* 区域大标题 */
.title1 {
  margin-top: 100px;
  font-size: 36px;
  color: #111;
}
.intro {
  font-size: 24px;
  color: #999;
  margin-top: 10px;
}
.intro_2 {
  font-size: 18px;
  color: #f2ab3c;
  margin-top: 10px;
}

.realTime {
  background: #f3f3f8;
  padding: 80px 0;
  height: 700px;
  .realTime_box {
    display: flex;
    flex-direction: row;
    padding-left: 150px;
    // img {
    //   width: 1200px;
    //   height: 987px;
    // }
    .realTime_right {
      margin-left: 50px;
      .realTime_title {
        height: 145px;
        padding-top: 1px;
        padding-left: 10px;
        background: url('../../assets/image/产品服务/充电桩/标题-bg.png')
          no-repeat;
        h2 {
          font-size: 36px;
          color: #111;
          margin-top: 66px;
        }
      }
      .realTime_content {
        margin-top: 50px;
        width: 440px;
        font-size: 24px;
        color: #666;
        line-height: 40px;
      }
    }
  }
}
.businessHall {
  display: flex;
  flex-direction: row;
  padding: 80px 0 80px 300px;
  height: 700px;
  .businessHall_title {
    height: 145px;
    padding-top: 66px;
    h2 {
      font-size: 36px;
      color: #111;
    }
    background: url('../../assets/image/产品服务/充电桩/标题-bg.png') no-repeat;
  }
  .businessHall_content {
    width: 537px;
    grid-area: content;
    font-size: 24px;
    color: #666;
    line-height: 40px;
    margin-right: 50px;
  }
}
</style>
